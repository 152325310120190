import React, {Component} from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faFacebookF, faInstagram, faLinkedin, faGitlab, faCodepen  } from '@fortawesome/free-brands-svg-icons'

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="find-me">
                    <div className="d-flex justify-content-center">
                        <a target="_blank" href="https://github.com/Zlerp" className="social-link p-3">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                        <a target="_blank" href="https://codepen.io/Zlerp" className="social-link p-3">
                            <FontAwesomeIcon icon={faCodepen} />
                        </a>
                        <a target="_blank" href="https://www.facebook.com/zlerp" className="social-link p-3">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a target="_blank" href="https://www.instagram.com/zlerp/" className="social-link p-3">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/in/zachperlman/" className="social-link p-3">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a target="_blank" href="https://gitlab.com/Zlerp" className="social-link p-3">
                            <FontAwesomeIcon icon={faGitlab} />
                        </a>
                    </div>
                </div>
                <footer id="sticky-footer" className="py-4 text-white-50">
                    <div className="container text-center">
                        <small>Copyright &copy; Zlerp Labs - 2020</small>
                    </div>
                </footer>
            </React.Fragment>

        );
    }
}

Footer.propTypes = {};

export default Footer;
