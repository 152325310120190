import React, {Component} from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import './Header.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';



class Header extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);

        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }



    closeNavbar(){
        this.setState({
            collapsed: true
        });

    }

    renderLinks() {
        return this.props.links.map(function(link, index){
            return (
                <NavItem key={index}>
                    {/*<NavLink className='text-capitalize' href={`/${link === 'home' ? '' : link}`}>{link}</NavLink>*/}
                    <AnchorLink className='text-capitalize nav-link' onClick={this.closeNavbar} href={`#${link}`}>{link}</AnchorLink>

                </NavItem>
            );
        }, this);
    }

    render() {
        return (
            <Navbar className="header" color="faded" light expand="md"  fixed="top" >
                {/*<NavbarBrand href="/" className="mr-auto"><span className="pacifico">Zlerp</span></NavbarBrand>*/}
                <AnchorLink href="#home" className="mr-auto navbar-brand"><span className="pacifico">Zlerp</span></AnchorLink>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar className="ml-auto" >
                        {this.renderLinks()}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

Header.propTypes = {};

export default Header;
