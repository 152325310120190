import React, {Component} from 'react';
import './Home.scss';

import ContactForm from "./../../partials/contact-form/ContactForm";

import { easeQuadInOut } from "d3-ease";
import { CircularProgressbar,  buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from "./../../../helpers/AnimatedProgressProvider";
import { Card, CardText, CardBody, CardTitle} from 'reactstrap';

import HeroScene from '../../partials/hero-scene/HeroScene';
import TrackVisibility from 'react-on-screen';

import { ReactComponent as Robo } from './../../../assets/svg/robo.svg';



class Home extends Component {

    skills = [
        {
            name: 'HTML',
            level: 95,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'CSS',
            level: 94,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'JS',
            level: 83,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'React',
            level: 76,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'Bootstrap',
            level: 96,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'Node',
            level: 68,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'SQL/NoSQL',
            level: 55,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        },
        {
            name: 'AWS',
            level: 35,
            txtColor: 'white',
            pathColor: 'turquoise',
            trailColor: 'gold',
        }
    ];

    projects = [
        {
            title: 'Camshaft Capital',
            subTitle: 'Subtitle Neat',
            cardText: 'WIP - Making a Broker a digital Home with React',
            image: '/imgs/projects/Camshaft.jpg',
            link: 'https://dev.camshaftcapital.com/',
        },
        {
            title: 'Peerless Mastering',
            subTitle: 'Subtitle Neat',
            cardText: 'WIP - Modernizing Mastering Studio Website with React',
            image: '/imgs/projects/peerless.jpg',
            link: 'http://dev.peerlessmastering.com/',
        },
        {
            title: 'Star Wars Info',
            subTitle: 'Subtitle Neat',
            cardText: 'Some fun with Star Wars API SWAPI',
            image: '/imgs/projects/sw.jpg',
            link: 'https://zlerp.github.io/react-StarWars/',
        },
        // {
        //     title: 'Crypto Screener',
        //     subTitle: 'Subtitle Neat',
        //     cardText: '#1 Crypto Screener For Bitcoin and Alt Coins',
        //     image: '/imgs/projects/cryptoscreener.png',
        //     link: 'http://cryptoscreener.com/',
        // },
        {
            title: '24hourCrypto',
            subTitle: 'Subtitle Neat',
            cardText: 'One Stop shop for all your crypto news and info',
            image: '/imgs/projects/24hCrypto.png',
            link: 'http://www.24hourcrypto.com/',
        },
        {
            title: 'Rapflix',
            subTitle: 'Subtitle Neat',
            cardText: 'The "Netflix of Hip Hop and Urban culture". Built in React',
            image: '/imgs/projects/rf.png',
            link: 'https://rapflix.com/',
        },
        {
            title: 'Modtech',
            subTitle: 'Modtech',
            cardText: 'One stop shop for all your needs on the web.',
            image: '/imgs/projects/modtech.png',
            link: 'https://www.modtech.io/',
        },
        {
            title: 'Mini Donut World',
            subTitle: 'Subtitle Neat',
            cardText: 'Making this mom and pop donut shop shine!',
            image: '/imgs/projects/mdw.png',
            link: 'http://minidonutworld.com/',
        },
        {
            title: 'Sweet Settlements',
            subTitle: 'Subtitle Neat',
            cardText: 'A titling service company',
            image: '/imgs/projects/ss.png',
            link: 'http://134.209.45.202/',
        },
        {
            title: 'Crypto Corp',
            subTitle: 'Subtitle Neat',
            cardText: 'A network I created for all crypto related web projects.',
            image: '/imgs/projects/cc.png',
            link: 'http://crypto-corp.com/',
        },        {
            title: 'Team HODL',
            subTitle: 'Subtitle Neat',
            cardText: 'A investment group platform to help stay organize and transparent.',
            image: '/imgs/projects/TH.png',
            link: 'https://team-hodl.com',
        },

    ];


    renderSkills () {
        return this.skills.map(function(skill, index){
            return (
                <TrackVisibility tag="div" className="col-6 col-md-3 p-2" key={index} offset={100} once>
                    {({ isVisible }) => isVisible &&
                        <React.Fragment>
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={skill.level}
                                    duration={2.5}
                                    easingFunction={easeQuadInOut}
                                >
                                    {value => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbar
                                                className={`p-4 circle-progress`}
                                                value={value}
                                                text={`${roundedValue}%`}
                                                style={{animationDelay: index + 's'}}
                                                styles={buildStyles({
                                                    pathTransition: "none",
                                                    textColor: skill.txtColor,
                                                    pathColor: skill.pathColor,
                                                    trailColor: skill.trailColor,
                                                })}

                                            />
                                        );
                                    }}
                                </AnimatedProgressProvider>
                                <h3 className="text-center">{skill.name}</h3>

                        </React.Fragment>
                   }
                </TrackVisibility>
            );
        });
    }


    renderCards () {
        return this.projects.map(function(project, index){
            return (
                <div key={index} className="col-12 col-sm-6 col-md-4 mb-4">
                    <a className="project__links" target="_blank" href={project.link}>
                        <Card>
                            <div className="card-img-wrapper" style={{background: `url(${project.image}`}}>

                            </div>
                            <CardBody className="pt-0">
                                <CardTitle><h4>{project.title}</h4></CardTitle>
                                {/*<CardSubtitle>{project.subTitle}</CardSubtitle>*/}
                                <CardText>{project.cardText}</CardText>
                            </CardBody>
                        </Card>
                    </a>
                </div>
            );
        });
    }
    render() {
        return (
            <div className="min-vh-100">

                <HeroScene/>

                <div id="about" className="about py-section">
                    <div className="container">
                        <h1 className="section__title text-center">About</h1>
                        <div className="row ">
                            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mb-5 mb-lg-0">
                                <p>Born and raised in sunny Miami. I found my passion for developing later in life. I got my BA studying Economics at FIU. I tried the climbing of the corporate ladder. I realized quickly that it wasn’t for me at all. I was always into gaming, on my computer, or outside playing sports with friends as a kid. So I wasn’t sure what I wanted to do, but I knew it was something in the technology scene. I quit my job, and made the career move into development in 2015 when I joined a programming bootcamp.</p>
                                <p className="mb-0">Since then I’ve been loving what I do. I know I made the right decision for myself when I found myself actually enjoying work. A few years later and now I find myself developing almost everyday. Building large scale applications collaborating with teams. Or just messing around building whatever project I decide to on the side.  During my time I was able to learn how to work with both the design and interface, as well as the backend. I love being able to not only dream something up, but actually be able to build it to. I guess you can just say I’m a Happy Coder.</p>
                            </div>
                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                {/*<img  className='about__img' src="/imgs/me.jpeg" alt=""/>*/}
                                <Robo height="300"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="skills" className="skills py-section">
                    <div className="container">
                        <h1 className="section__title text-center">Skills</h1>
                        <div className="row">
                            {this.renderSkills()}
                        </div>
                    </div>
                </div>

                <div id="projects" className="projects py-section">
                    <div className="container">
                        <h1 className="section__title text-center">Projects</h1>
                        <div className="row">
                            {this.renderCards()}
                        </div>
                    </div>
                </div>
                <ContactForm/>
            </div>

        );
    }
}

Home.propTypes = {};

export default Home;
