import React, {Component} from 'react';
import { ReactComponent as Robot } from './../../../assets/svg/robot.svg';
import { ReactComponent as Mountains } from './../../../assets/svg/mountains.svg';
import { ReactComponent as ZlerpLogo } from './../../../assets/svg/zlerp.svg';

import './HeroScene.scss';

import {isMobile} from 'react-device-detect';


import ArrowKeysReact from 'arrow-keys-react';

class HeroScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            robotLeft: 30
        };
        ArrowKeysReact.config({
            left: () => {
                let lastLeft = this.state.robotLeft;
                if (lastLeft > 10) {
                    lastLeft = lastLeft - 2;
                    this.setState({
                        robotLeft: lastLeft
                    });
                }

            },
            right: () => {
                let lastLeft = this.state.robotLeft;
                if (lastLeft < 100) {
                    lastLeft = lastLeft + 1;
                    this.setState({
                        robotLeft: lastLeft
                    });
                }

            }
        });
    }


    componentDidMount() {
        document.getElementById('home').focus();
    }

    render() {
        return (
            <div id="home"  className="hero-bg min-vh-100" {...ArrowKeysReact.events} tabIndex="1" style={{    outline: '0!important', boxShadow: 'none!important'}}>
                <Mountains className="bg-fullscreen animate-lr"/>
                <Robot className="svg-robot" style={{left: `calc(${isMobile ? '50' : this.state.robotLeft}% - 120px`}}/>

                <div className="hero__text">
                    <div className="text-center hero__text-title">
                        <ZlerpLogo/>
                    </div>
                    <p className="hero__text-subTitle">Web Wizard</p>
                </div>
            </div>
        );
    }
}

HeroScene.propTypes = {};

export default HeroScene;
