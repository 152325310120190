import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from './components/partials/header/Header';
import Footer from './components/partials/footer/Footer';

import Home from './components/pages/home/Home';
// import About from './components/pages/About';
// import Contact from './components/pages/Contact';
// import Projects from './components/pages/Projects';


function App() {

  // const routerLinks = ['home'];
  const routerLinks = ['home', 'about', 'skills', 'projects', 'contact'];

  return (
      <Router>
        <div>
          <Header links={routerLinks}/>

          <Route exact path="/" component={Home} />
          {/*<Route path="/projects" component={Projects} />*/}
          {/*<Route path="/about" component={About} />*/}
          {/*<Route path="/contact" component={Contact} />*/}

          <Footer/>
        </div>
      </Router>
  );
}

export default App;
