import React, {Component} from 'react';
import './ContactForm.scss';


class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: ''
        };
    }

    handleMessage = (e) => {
        let newMessage = e.target.textContent;

        this.setState({
            message: newMessage
        });
    };

    render() {
        return (
            <div id="contact" className="contact-form">
                <form action="https://formspree.io/z.h.perlman@gmail.com" method="POST">
                    <div className="container">
                        <h1 className="section__title text-center">Contact Me</h1>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="formName">Name</label>
                                    <input type="text" name="name" className="form-control" id="formName" aria-describedby="emailHelp" placeholder="Enter name" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="formEmail">Email address</label>
                                    <input type="email" name="email" className="form-control" id="formaEmail" aria-describedby="emailHelp" placeholder="Enter email" />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="form-group">
                                    <label htmlFor="formComments">What would you like to do?</label>
                                    <div contentEditable className="form-control" onKeyUp={this.handleMessage} id="formComments" />
                                    <textarea name="comments" className="d-none" rows="2" readOnly value={this.state.message}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-outline-light" type="submit">Send</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
}

ContactForm.propTypes = {};

export default ContactForm;
